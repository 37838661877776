/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div",
    blockquote: "blockquote",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Los duendes de las estadísticas de WordPress.com prepararon un informe sobre el año 2012 de este blog."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.wordpress.com/2012/annual-report/"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 600px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 56.9620253164557%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDBf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABzVJxkBi//8QAGRAAAwADAAAAAAAAAAAAAAAAAAECAxAR/9oACAEBAAEFAscKi44hD1//xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQMBAT8Bmo//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGn/8QAFRABAQAAAAAAAAAAAAAAAAAAICH/2gAIAQEABj8Cq//EABoQAQACAwEAAAAAAAAAAAAAAAEAERAxQYH/2gAIAQEAAT8hB4YTeVEpqNNMTj//2gAMAwEAAgADAAAAEEz/AP/EABYRAQEBAAAAAAAAAAAAAAAAABEAAf/aAAgBAwEBPxDCmF//xAAWEQEBAQAAAAAAAAAAAAAAAAABEQD/2gAIAQIBAT8QbJq7/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFBYXGBkf/aAAgBAQABPxB4KyqTVZ38iWgq2dZuO2HpuUFhnhjBtXyx3P/Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"emailteaser\"\n        title=\"emailteaser\"\n        src=\"/static/59417058414f694cf1051f938c358c2e/b4294/emailteaser.jpg\"\n        srcset=\"/static/59417058414f694cf1051f938c358c2e/ff44c/emailteaser.jpg 158w,\n/static/59417058414f694cf1051f938c358c2e/a6688/emailteaser.jpg 315w,\n/static/59417058414f694cf1051f938c358c2e/b4294/emailteaser.jpg 600w\"\n        sizes=\"(max-width: 600px) 100vw, 600px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "Aquí hay un extracto:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "600 personas llegaron a la cima del monte Everest in 2012. Este blog tiene ", React.createElement(_components.strong, null, "9.100"), " visitas en 2012. Si cada persona que ha llegado a la cima del monte Everest visitara este blog, se habría tardado 15 años en obtener esas visitas."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.wordpress.com/2012/annual-report/"
  }, "Haz click para ver el reporte completo.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
